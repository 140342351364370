@import '../../../../variables.css';

.container {
    width: 100%;
    margin: 0;
    background-repeat: no-repeat;
    background-position: top;
    background-size: contain;
    position: relative;
}

.container::after {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 5px;
    background: linear-gradient(96.1deg, #694B14 3.79%, #FFD04D 25.82%, #614717 66.43%, #FFE6B8 94.65%);
}

.content {
    display: block;
    width: 100%;
    padding: 80px 0;
    margin: 0 auto;
    z-index: 1;
    text-align: left;
    font-family: 'Readex Pro', sans-serif;
    font-weight: var(--ark-font-weight-regular);
}

.mainTitle {
    font-size: var(--ark-48-font-size);
    line-height: var(--ark-56-line-height);
    margin-bottom: 40px;
    text-align: center;
}

.content .item {
    width: 100%;
    max-width: 1250px;
    margin: auto auto 20px auto;
    background-color: transparent;
    box-shadow: none;
}

.item::before {
    display: none;
}

.content .itemTitle {
    width: 100%;
    border-radius: 16px;
    padding: 9px 32px;
    box-shadow: none;
    border: 1px solid var(--ark-neutral-white);
}

.content .itemTitle p {
    font-family: inherit;
    font-size: var(--ark-20-font-size);
    line-height: var(--ark-28-line-height);
}

.content .itemDetails {
    background-color: transparent;
    padding: 8px 0px 16px 8px;
}

.content .itemDetails .itemDetailsContent {
    font-family: inherit;
    white-space: pre-wrap;
    font-size: var(--ark-18-font-size);
    line-height: var(--ark-25-line-height);
}

.content .itemTitle .icon {
    height: 1.5rem;
    rotate: 180deg;
}

@media (max-width: var(--ark-small-desktop-figma-1280)) {
    .content .rightSide h2 {
        margin-bottom: 20px;
        font-size: var(--ark-40-font-size);
    }
}

@media (max-width: var(--ark-medium-mobile-figma-768)) {
    .content {
        width: 95%;
        padding: 32px 0;
    }

    .content .mainTitle {
        margin-bottom: 40px;
        font-size: var(--ark-28-font-size);
        line-height: var(--ark-40-line-height);
    }

    .content .itemTitle p {
        font-size: var(--ark-20-font-size);
        line-height: var(--ark-32-line-height);
    }

    .content .itemDetails .itemDetailsContent {
        font-size: var(--ark-16-font-size);
        line-height: var(--ark-22-line-height);
    }
}

@media (max-width: var(--ark-small-mobile-figma-600)) {
    .content .itemTitle {
        padding: 5px 15px;
    }
}
